import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { WEB_PUSH_PUBLIC_KEY } from "config.js";
import axios from "axios";

function urlBase64ToUint8Array(base64String) {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

function deleteSubscriptionFromServer() {
	return axios.delete("api/push-subscription", { requestid: "api/push-subscription" });
}

function postSubscriptionToServer(subscription) {
	const key = subscription.getKey("p256dh");
	const token = subscription.getKey("auth");

	return axios.post(
		"api/push-subscription",
		{
			endpoint: subscription.endpoint,
			key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
			token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null
		},
		{ requestid: "api/push-subscription" }
	);
}

export function subscribe(setNotificationsSubscription) {
	if (!navigator.serviceWorker) return;
	navigator.serviceWorker.ready
		.then(serviceWorkerRegistration =>
			serviceWorkerRegistration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY)
			})
		)
		.then(subscription => {
			postSubscriptionToServer(subscription).then(result => {
				setNotificationsSubscription("subscribed");
			});
		});
}

export function unsubscribe(setNotificationsSubscription) {
	if (!navigator.serviceWorker) return;
	navigator.serviceWorker.ready
		.then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
		.then(subscription => {
			if (!subscription) {
				setNotificationsSubscription("not-subscribed");
				return;
			}
			deleteSubscriptionFromServer().then(result => {
				setNotificationsSubscription("not-subscribed");
			});
		});
}

export function updateSubscription(setNotificationsSubscription) {
	if (!navigator.serviceWorker) return;
	navigator.serviceWorker.ready
		.then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
		.then(subscription => {
			if (!subscription) {
				deleteSubscriptionFromServer().then(result => {
					setNotificationsSubscription("not-subscribed");
				});
			} else {
				postSubscriptionToServer(subscription).then(result => {
					setNotificationsSubscription("subscribed");
				});
			}
		});
}

export default class NotificationsPanel extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		notificationsPermission: PropTypes.string,
		notificationsSubscription: PropTypes.string
	};

	onSubscribeClick = () => {
		subscribe(this.props.setNotificationsSubscription);
	};

	onUnsubscribeClick = () => {
		unsubscribe(this.props.setNotificationsSubscription);
	};

	render() {
		const { t, notificationsPermission, notificationsSubscription } = this.props;

		if (!navigator.serviceWorker) {
			return <div className="NotificationsPanel error button hollow">{t("NotificationsPanel.no-support")}</div>;
		}

		if (notificationsPermission === "denied") {
			return <div className="NotificationsPanel warning button hollow">{t("NotificationsPanel.denied")}</div>;
		}
		if (notificationsSubscription === undefined) return null;

		return (
			<div className="NotificationsPanel">
				{notificationsSubscription === "subscribed" && (
					<button className="promo button unsubscribe hollow" onClick={this.onUnsubscribeClick}>
						{t("NotificationsPanel.unsubscribe")}
					</button>
				)}
				{notificationsSubscription === "not-subscribed" && (
					<button className="button subscribe" onClick={this.onSubscribeClick}>
						{t("NotificationsPanel.subscribe")}
					</button>
				)}
			</div>
		);
	}
}
