import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Router, Route, Switch } from "react-router-dom";
import "sass/main.scss";
import history from "./history.js";
import NotificationsPanel from "common/NotificationsPanel.js";
import { loadBlocksFromServer, loadUserFromServer } from "./DataAccess.js";
import LocaleSwitcher from "common/LocaleSwitcher.js";
import Loading from "common/Loading.js";
import { signOutFromDrupal } from "common/user.js";
import { updateSubscription, unsubscribe } from "common/NotificationsPanel.js";
import { isSignedIn, isSignedOut, hasSubscription } from "common/user.js";
import { clearAllVisited, clearAllClicked } from "pages/map/DataAccess";
import TermsModal from "./TermsModal.js";

const Content = lazy(() => import("pages/content/Content.js"));
const MapNavigator = lazy(() => import("pages/map/MapNavigator.js"));
const Profile = lazy(() => import("pages/profile/Profile.js"));

export default class App extends Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		locale: PropTypes.string.isRequired,
		setLocale: PropTypes.func.isRequired
	};

	state = {
		geolocationPermission: undefined, // ['granted', 'denied', 'prompt'] // https://developer.mozilla.org/en-US/docs/Web/API/PermissionStatus/state
		notificationsPermission: undefined, // ['granted', 'denied', 'prompt'] // https://developer.mozilla.org/en-US/docs/Web/API/PermissionStatus/state
		notificationsSubscription: undefined, // ['subscribed', 'not-subscribed']
		blocks: {},
		user: undefined
	};

	componentDidMount() {
		this.loadUserFromServerAndSetState();
		this.loadDataFromServerAndSetState();
		if (!navigator.permissions) return; // IE?
		navigator.permissions.query({ name: "geolocation" }).then(permissionStatus => {
			console.log("Geolocation permission: " + permissionStatus.state);
			this.setState({ geolocationPermission: permissionStatus.state });
			permissionStatus.onchange = e => {
				const permissionStatus = e.currentTarget;
				this.setState({ geolocationPermission: permissionStatus.state });
				console.log("Geolocation permission updated to: " + permissionStatus.state);
			};
		});
		navigator.permissions.query({ name: "notifications" }).then(permissionStatus => {
			console.log("Notifications permission: " + permissionStatus.state);
			this.setState({ notificationsPermission: permissionStatus.state });
			permissionStatus.onchange = e => {
				const permissionStatus = e.currentTarget;
				this.setState({ notificationsPermission: permissionStatus.state });
				console.log("Notifications permission updated to: " + permissionStatus.state);
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.locale !== this.props.locale) {
			console.log(
				`Locale changed from ${prevProps.locale} to ${this.props.locale}. Reloading content from server`
			);
			this.loadDataFromServerAndSetState();
		}
	}

	signOut = () => {
		unsubscribe(this.setNotificationsSubscription);
		// Maybe it'd be better to fully wipe the localStorage instead of having
		// to (remember to) call all individual localStorage related functions.
		clearAllVisited();
		clearAllClicked();
		signOutFromDrupal()
			.then(() => {
				this.setState({ user: { id: 0 } });
			})
			.catch(errors => {
				console.log(errors);
				this.setState({ user: { id: 0 } });
			});
	};

	updateUserState = user => {
		if (!this.state.user) return;
		this.setState({ user: user });
	};

	loadDataFromServerAndSetState() {
		loadBlocksFromServer().then(blocks => {
			this.setState({ blocks: blocks });
		});
	}

	loadUserFromServerAndSetState() {
		loadUserFromServer().then(user => {
			this.setState({ user: user });
			if (isSignedIn(user) && hasSubscription(user)) {
				setTimeout(
					() => updateSubscription(this.setNotificationsSubscription),
					// Low priority server update, so send after 200ms.
					// In the future, if supported, we should remove setTimeout and send this with priority hint: "low".
					200
				);
			}
			if (isSignedIn(user) && !hasSubscription(user)) {
				this.setNotificationsSubscription("not-subscribed");
			}
		});
	}

	setNotificationsSubscription = status => {
		this.setState({ notificationsSubscription: status });
	};

	render() {
		const { t, locale, setLocale } = this.props;
		const { notificationsPermission, notificationsSubscription, blocks, user } = this.state;
		const localeSwitcher = <LocaleSwitcher locale={locale} setLocale={setLocale} />;
		const notificationsPanel = (
			<NotificationsPanel
				t={t}
				notificationsPermission={notificationsPermission}
				notificationsSubscription={notificationsSubscription}
				setNotificationsSubscription={this.setNotificationsSubscription}
			/>
		);
		return (
			<Router history={history}>
				<div
					className={
						"App row collapse" +
						(isSignedIn(user) ? " userSignedIn" : "") +
						(isSignedOut(user) ? " userSignedOut" : "")
					}
				>
					<Switch>
						{/* prettier-ignore */}
						<Route sensitive strict exact path="/" render={props => <Suspense fallback={<Loading t={t} />}><Content t={t} locale={locale} user={user} url="home" blocks={blocks} localeSwitcher={localeSwitcher} signOut={this.signOut} /></Suspense>} />
						{/* prettier-ignore */}
						<Route sensitive strict exact path="/map" render={props => <Suspense fallback={<Loading t={t} />}><MapNavigator t={t} user={user} updateUserState={this.updateUserState} signOut={this.signOut} /></Suspense>} />
						{/* prettier-ignore */}
						<Route sensitive strict exact path="/map/:cityId([1-9][0-9]*)/:subpage(did-you-know|places|wall-of-fame|submit-poi|submit-story)?" render={props => <Suspense fallback={<Loading t={t} />}><MapNavigator t={t} user={user} updateUserState={this.updateUserState} signOut={this.signOut} urlCityId={Number(props.match.params.cityId)} urlSubPage={props.match.params.subpage} urlSearch={props.location.search} /></Suspense>} />
						{/* prettier-ignore */}
						<Route sensitive strict exact path="/map/:cityId([1-9][0-9]*)/:poiId([1-9][0-9]*)/:subpage(review|edit)?" render={props => <Suspense fallback={<Loading t={t} />}><MapNavigator t={t} user={user} updateUserState={this.updateUserState} signOut={this.signOut} urlCityId={Number(props.match.params.cityId)} urlPoiId={Number(props.match.params.poiId)} urlSubPage={props.match.params.subpage} urlSearch={props.location.search} /></Suspense>} />
						{/* prettier-ignore */}
						<Route sensitive strict exact path="/map/:cityId([1-9][0-9]*)/:poiId([1-9][0-9]*)/:poiItemId([1-9][0-9]*)/:subpage(review|solve|visit|discuss)?" render={props => <Suspense fallback={<Loading t={t} />}><MapNavigator t={t} user={user} updateUserState={this.updateUserState} signOut={this.signOut} urlCityId={Number(props.match.params.cityId)} urlPoiId={Number(props.match.params.poiId)} urlPoiItemId={Number(props.match.params.poiItemId)} urlSubPage={props.match.params.subpage} urlSearch={props.location.search} /></Suspense>} />
						{/* prettier-ignore */}
						<Route sensitive strict exact path="/profile" render={props => <Suspense fallback={<Loading t={t} />}><Profile t={t} user={user} updateUserState={this.updateUserState} signOut={this.signOut} notificationsPanel={notificationsPanel} /></Suspense>} />
						{/* prettier-ignore */}
						<Route sensitive strict exact path="/:url(.*)" render={props => <Suspense fallback={<Loading t={t} />}><Content t={t} locale={locale} user={user} signOut={this.signOut} blocks={blocks} localeSwitcher={localeSwitcher} url={props.match.params.url} /></Suspense>} />
					</Switch>
					<Route
						render={props => (
							<TermsModal
								t={t}
								user={user}
								updateUserState={this.updateUserState}
								signOut={this.signOut}
								url={props.location.pathname}
							/>
						)}
					/>
				</div>
			</Router>
		);
	}
}
