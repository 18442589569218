import axios from "axios";
import { slugifyTextForSearch } from "common/utils.js";
import { GEOLOCATION_CONFIG, MAX_CLICKED_HISTORY } from "config.js";

export function axiosCancelAll() {
	axios.cancel("api/map-data");
	axios.cancel("api/city-data");
	axios.cancel("api/poi-items");
	axios.cancel("api/set-user-city");
	axios.cancel("api/favourite");
}

export function loadMapDataFromServer() {
	function processCountries(data) {
		const indexedResults = {};
		for (const country of data) {
			// index result
			indexedResults[country.id] = country;
		}
		return indexedResults;
	}
	function processCities(data) {
		const indexedResults = {};
		for (const city of data) {
			// fix bounds
			if (city.corner_1_lat > city.corner_2_lat)
				[city.corner_1_lat, city.corner_2_lat] = [city.corner_2_lat, city.corner_1_lat];
			if (city.corner_1_lon > city.corner_2_lon)
				[city.corner_1_lon, city.corner_2_lon] = [city.corner_2_lon, city.corner_1_lon];
			// to be loaded in later on demand
			city.content = { didYouKnow: [] };
			// index result
			indexedResults[city.id] = city;
		}
		return indexedResults;
	}
	function processPoiCategories(data) {
		for (const category of data) {
			category.key = String(category.id);
			if (category.parent) category.parentKey = String(category.parent);
		}
		return data;
	}
	return axios.get("api/map-data", { requestId: "api/map-data" }).then(result => {
		return {
			countries: processCountries(result.data.countries),
			cities: processCities(result.data.cities),
			poiCategories: processPoiCategories(result.data.poiCategories)
		};
	});
}

export function loadCityDataFromServer(cityId, categories) {
	const indexedCategories = categories.reduce(function(map, category) {
		map[category.key] = category;
		return map;
	}, {});
	function processPois(data) {
		const indexedResults = {};
		for (const poi of data) {
			marshalPoi(poi, indexedCategories);
			// index result
			indexedResults[poi.id] = poi;
		}
		return indexedResults;
	}
	function processContent(content) {
		// some transformations
		content.didYouKnow = content.didYouKnow ? content.didYouKnow.trim().split("\r\n") : [];
		return content;
	}
	return axios.get("api/city-data?id=" + Number(cityId), { requestId: "api/city-data" }).then(result => {
		return {
			pois: processPois(result.data.pois),
			content: processContent(result.data.content)
		};
	});
}

export function marshalPoi(poiFromServer, indexedCategories) {
	if (poiFromServer.items) {
		poiFromServer.itemsLoaded = false;
	} else {
		poiFromServer.itemsLoaded = true;
		poiFromServer.items = [];
	}
	const indexedItems = {};
	for (const item of poiFromServer.items) {
		indexedItems[item.id] = item;
	}
	poiFromServer.items = indexedItems;
	// some transformations
	poiFromServer.large_icon_size = poiFromServer.large_icon_size === true;
	poiFromServer.categories = poiFromServer.categories
		? poiFromServer.categories
				.map(categoryKey => indexedCategories[categoryKey])
				.filter(category => category !== undefined) // remove not resolved - maybe rare to happen
		: [];
	poiFromServer.searchTitle = slugifyTextForSearch(poiFromServer.title);
}

export function loadPoiItemsFromServer(poiId) {
	return axios.get("api/poi-items?id=" + Number(poiId), { requestId: "api/poi-items" }).then(result => {
		const indexedResults = {};
		for (const poiItem of result.data) {
			indexedResults[poiItem.id] = poiItem;
		}
		return indexedResults;
	});
}

export function getLocationFromDevice() {
	return new Promise(function(resolve, reject) {
		if (!navigator.geolocation) {
			reject();
		}
		navigator.geolocation.getCurrentPosition(
			position => {
				resolve(position);
			},
			positionError => {
				reject(positionError);
			},
			GEOLOCATION_CONFIG
		);
	}).then(position => {
		return {
			accuracy: position.coords.accuracy,
			lat: position.coords.latitude,
			lon: position.coords.longitude
		};
	});
}

export function sendSelectedUserCityToServer(selectedCityId) {
	console.log(`Setting city for user on backend: ${selectedCityId}`);
	return axios.post("api/set-user-city", selectedCityId, { requestId: "api/set-user-city" });
}

export function setFavourite(contentId) {
	return axios.post("api/favourite?id=" + Number(contentId), { requestId: "api/favourite" });
}

export function unsetFavourite(contentId) {
	return axios.delete("api/favourite?id=" + Number(contentId), { requestId: "api/favourite" });
}

export function trackClick(contentId) {
	return axios.post("api/click?id=" + Number(contentId), { requestId: "api/click" });
}

export function trackVisit(contentId) {
	return axios.post("api/visit?id=" + Number(contentId), { requestId: "api/visit" });
}

function getVisitedWithTimestamps() {
	return JSON.parse(window.localStorage.getItem("visited")) || {};
}
export function storeVisit(contentId, now = Date.now()) {
	const visited = getVisitedWithTimestamps();
	visited[contentId.toString()] = {
		i: contentId,
		t: now
	};
	window.localStorage.setItem("visited", JSON.stringify(visited));
}

export function getVisited() {
	return Object.values(getVisitedWithTimestamps()).map(visited => visited.i);
}

export function clearOldVisited(ttl, now = Date.now()) {
	const latestVisited = {};
	Object.entries(getVisitedWithTimestamps()).forEach(([key, { i: item, t: timestamp }]) => {
		if (now - timestamp < ttl)
			latestVisited[key] = {
				i: item,
				t: timestamp
			};
	});
	window.localStorage.setItem("visited", JSON.stringify(latestVisited));
}

export function clearAllVisited() {
	window.localStorage.clear("visited");
}

export function storeClick(contentId, maxClickedHistory = MAX_CLICKED_HISTORY) {
	const clicked = getClicked();
	const index = clicked.indexOf(contentId);
	if (index >= 0) {
		clicked.splice(index, 1);
	}
	clicked.push(contentId);
	while (clicked.length > maxClickedHistory) {
		clicked.shift(); // pop from beginning
	}
	window.localStorage.setItem("clicked", JSON.stringify(clicked));
}

export function getClicked() {
	return JSON.parse(window.localStorage.getItem("clicked")) || [];
}

export function clearAllClicked() {
	window.localStorage.clear("clicked");
}
