import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { LOCALES } from "config.js";

export default class LocaleSwitcher extends PureComponent {
	static propTypes = {
		locale: PropTypes.string.isRequired,
		setLocale: PropTypes.func.isRequired
	};

	render() {
		const { locale, setLocale } = this.props;
		return (
			<div className="LocaleSwitcher">
				<select aria-label="Language" value={locale} onChange={e => setLocale(e.target.value)}>
					{LOCALES.map(locale => (
						<option key={locale.code} value={locale.code}>
							{locale.title}
						</option>
					))}
				</select>
			</div>
		);
	}
}
