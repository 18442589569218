import React, { Component } from "react";
import Polyglot from "node-polyglot";
import { LOCALES, BACKEND_URL } from "config.js";
import axios from "axios";
import App from "./App.js";

let polyglot;

export default class AppWithTranslate extends Component {
	state = {
		loadedPolyglotLocale: undefined
	};

	componentDidMount() {
		this.loadLocale(this.getCurrentLocale());
	}

	setAxiosBaseTo(locale) {
		const axiosBaseURL = BACKEND_URL + (locale ? locale : "");
		if (axios.defaults.baseURL === axiosBaseURL) return;
		console.log("Setting axios.defaults.baseURL to " + axiosBaseURL);
		axios.defaults.baseURL = axiosBaseURL;
	}

	getCurrentLocale() {
		const localStorageLocale = this.getLocalStorageLocale();
		if (this.isLocaleSupported(localStorageLocale)) return localStorageLocale;

		const browserLocale = this.getBrowserLocale();
		if (this.isLocaleSupported(browserLocale)) return browserLocale;

		return this.getDefaultLocale();
	}

	isLocaleSupported(locale) {
		const supportedLocale = LOCALES.find(item => {
			return item.code === locale;
		});
		return supportedLocale ? true : false;
	}

	getLocalStorageLocale() {
		return window.localStorage.getItem("locale");
	}

	getBrowserLocale() {
		const browserLocale =
			navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
		// locale may contain "en-US"
		return browserLocale ? browserLocale.split("-")[0] : null;
	}

	getDefaultLocale() {
		return LOCALES[0].code;
	}

	setLocale = locale => {
		window.localStorage.setItem("locale", locale);
		this.loadLocale(locale);
	};

	loadLocale = locale => {
		console.log("Loading locale: " + locale);
		import("../i18n." + locale + ".js").then(i18n => {
			polyglot = new Polyglot({
				locale: locale,
				phrases: i18n.default
			});
			// Changing axios base to the new locale before updating the state locale.
			// That's because otherwise child components would be notified of change
			// and would possibly issue ajax requests to previous language.
			this.setAxiosBaseTo(locale);
			this.changeHtmlLang(locale);
			this.setState({ loadedPolyglotLocale: locale });
		});
	};

	changeHtmlLang(locale) {
		document.documentElement.setAttribute("lang", locale);
	}

	render() {
		if (!this.state.loadedPolyglotLocale)
			return (
				<div className="lds-ripple">
					<span />
					<span />
					<div>
						Loading language packs.<br />
						Please wait.
					</div>
				</div>
			);
		return (
			<App t={polyglot.t.bind(polyglot)} locale={this.state.loadedPolyglotLocale} setLocale={this.setLocale} />
		);
	}
}
