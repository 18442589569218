import axios from "axios";
import { BACKEND_ECAS_URL } from "config.js";
import PropTypes from "prop-types";

export const USER_PROPTYPE_SHAPE = PropTypes.shape({
	id: PropTypes.number.isRequired,
	name: PropTypes.string,
	language: PropTypes.string,
	firstname: PropTypes.string,
	lastname: PropTypes.string,
	nickname: PropTypes.string,
	picture: PropTypes.string,
	city: PropTypes.number,
	score: PropTypes.number,
	favourites: PropTypes.array,
	subscription: PropTypes.bool
});

export function isSignedIn(user) {
	return user !== null && typeof user === "object" && user.id > 0 ? true : false;
}

export function isSignedOut(user) {
	return user !== null && typeof user === "object" && user.id === 0 ? true : false;
}

export function signOutFromDrupal() {
	console.log("Signing out from drupal.");
	return axios.get("user/logout", { requestId: "user/logout" });
}

export function signInViaEcas() {
	const target =
		BACKEND_ECAS_URL + "?destination=" + encodeURIComponent(window.location.pathname + window.location.search);
	console.log("Moving user to ecas for signin/signup and return to current page.", target);
	window.location = target;
}

export function isFavourite(user, contentId) {
	if (!isSignedIn(user) || !user.hasOwnProperty("favourites")) return;
	return user.favourites.includes(contentId);
}

export function setFavourite(user, contentId) {
	if (isFavourite(user, contentId)) return user;
	return { ...user, favourites: user.favourites.concat(contentId) };
}

export function unsetFavourite(user, contentId) {
	if (!isFavourite(user, contentId)) return user;
	return { ...user, favourites: user.favourites.filter(id => id !== contentId) };
}

export function hasAvatar(user) {
	return typeof user.picture === "string" && user.picture.length > 0;
}

export function setAvatar(user, avatarUrl) {
	return { ...user, picture: avatarUrl };
}

export function removeAvatar(user) {
	return { ...user, picture: undefined };
}

export function setNickname(user, nickname) {
	return { ...user, nickname: nickname };
}

export function setCity(user, city) {
	return { ...user, city: city };
}

export function addPoints(user, points) {
	if (!(typeof points === "number" && points > 0)) return user;
	return { ...user, score: user.score + points };
}

export function getDisplayName(user) {
	// use drupal nickname if available
	if (user.nickname) return user.nickname;

	// fallback to using firstname and lastname (anonymized) if available
	if (user.firstname || user.lastname) {
		const name = [];
		if (user.firstname) name.push(user.firstname);
		if (user.lastname && user.lastname.length === 1) name.push(user.lastname);
		if (user.lastname && user.lastname.length > 1) name.push(user.lastname[0] + ".");
		return name.join(" ");
	}

	// fallback to drupal username
	return user.name;
}

export function hasSubscription(user) {
	return typeof user.subscription === "boolean" && user.subscription;
}

export function hasAcceptedTerms(user) {
	return user.acceptedTerms === true;
}

export function acceptedTerms(user) {
	return { ...user, acceptedTerms: true };
}
