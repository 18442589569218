import slugify from "slugify";

/**
 * Converts a flat array of elements into an array of trees.
 * Elements must contain:
 * - "id" int
 * - "parent" int
 *
 * Adds a "children" property and removes the "parent" property on all elements.
 *
 */
export function unflatten(elements) {
	if (!Array.isArray(elements)) throw new Error("Not an array");
	const elementsClone = clone(elements);
	const indexedElements = elementsClone.reduce(function(map, element) {
		map[element.id] = element;
		return map;
	}, {});
	const result = [];
	elementsClone.forEach(element => {
		element.children = [];
	});
	elementsClone.forEach(element => {
		const parentElement = indexedElements[element.parent];
		delete element.parent;
		if (parentElement) {
			parentElement.children.push(element);
		} else {
			result.push(element);
		}
	});
	return result;
}

function clone(array) {
	const result = [];
	array.forEach(element => {
		result.push({ ...element }); // works only for 1st level
	});
	return result;
}

const tagsRegex = /<[^>]+>/gi;
const multipleWhitespaceRegex = /\s+/g;

/**
 * Attempts to strip HTML tags from text.
 */
export function stripTags(html) {
	if (typeof html !== "string") return "";
	return html
		.replace(tagsRegex, " ")
		.trim()
		.replace(multipleWhitespaceRegex, " ");
}

/**
 * Attempts to generate a text description from the specified html for usage in
 * head meta. Current implementation will only use up to first 50 words.
 */
export function getPossibleMetaDescription(html) {
	const WORDS_LIMIT = 50;
	const text = stripTags(html);
	const firstWords = text.split(" ");
	if (firstWords.length < WORDS_LIMIT) return text;
	return firstWords.join(" ") + "...";
}

export function slugifyTextForSearch(text) {
	return slugify(text.toLowerCase());
}
