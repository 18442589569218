import axios from "axios";

const BLOCKS = "footer,footer-small";

export function loadBlocksFromServer(page) {
	return axios.get("api/blocks?blocks=" + BLOCKS, { requestId: "api/blocks" }).then(result => {
		return result.data;
	});
}

export function loadUserFromServer() {
	return axios.get("api/current-user", { requestId: "api/current-user" }).then(result => {
		if (result.data.error) return { id: 0 };
		const user = result.data;
		if (!user.hasOwnProperty("favourites")) {
			user.favourites = [];
		}
		return result.data;
	});
}

export function deleteProfile() {
	return axios.delete("api/current-user?profile=1", { requestId: "api/current-user" });
}

export function userAcceptedTerms() {
	return axios.post("api/current-user?acceptedTerms=1", { requestId: "api/current-user" });
}
