import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isSignedIn, hasAcceptedTerms, acceptedTerms, USER_PROPTYPE_SHAPE } from "common/user.js";
import { userAcceptedTerms, deleteProfile } from "./DataAccess.js";

let id = 0;

export default class TermsModal extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		user: USER_PROPTYPE_SHAPE,
		updateUserState: PropTypes.func.isRequired,
		url: PropTypes.string.isRequired
	};

	componentDidMount() {
		id++;
	}

	accept = e => {
		const { t, user, updateUserState } = this.props;
		if (this.checkboxRef.current.checked) {
			e.preventDefault();
			userAcceptedTerms()
				.then(result => {
					updateUserState(acceptedTerms(user));
				})
				.catch(errors => {
					window.alert(t("api.error"));
				});
		} else {
			// Do nothing. The required attribute of the checkbox will force the
			// native HTML5 required popup to show.
		}
	};

	deleteProfileAndSignOut = () => {
		const { t, signOut } = this.props;
		console.log(`Deleting profile.`);
		deleteProfile()
			.then(signOut)
			.catch(errors => {
				window.alert(t("api.error"));
			});
	};

	decline = e => {
		e.preventDefault();
		if (window.confirm(this.props.t("delete-profile.confirm"))) {
			this.deleteProfileAndSignOut();
		}
	};

	isTermsModalOpen() {
		return this.state.visible;
	}

	checkboxRef = React.createRef();

	render() {
		const { t, user, url } = this.props;
		if (!isSignedIn(user)) return null;
		if (hasAcceptedTerms(user)) return null;
		if (url === "/terms-conditions") return null;

		return (
			<div className="TermsModal reveal-overlay custom">
				<div className="reveal custom">
					<form>
						<h2>{t("TermsModal.title")}</h2>
						<div className="callout secondary">
							<p>
								<input
									id={`TermsModal-checkbox-${id}`}
									type="checkbox"
									required
									ref={this.checkboxRef}
								/>
								<label htmlFor={`TermsModal-checkbox-${id}`}>
									{t("TermsModal.checkbox")}{" "}
									<Link to="/terms-conditions">{t("MenuLink.terms-conditions")}</Link>
								</label>
							</p>
						</div>
						<div className="expanded button-group large stacked-for-small">
							<button
								aria-label={t("TermsModal.agree")}
								className="button icon-check success accept"
								onClick={this.accept}
							>
								<span>{t("TermsModal.agree")}</span>
							</button>
							<button
								aria-label={t("TermsModal.decline")}
								className="button icon-remove alert decline"
								onClick={this.decline}
							>
								<span>{t("TermsModal.decline")}</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
