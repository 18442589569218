import axios from "axios";
import axiosCancel from "axios-cancel";
import removeFbclid from "remove-fbclid";
import history from "skeleton/history.js";
import ReactPiwik from "react-piwik";

export const GEOLOCATION_CONFIG = {
	// https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/enableHighAccuracy
	enableHighAccuracy: true,
	// https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/maximumAge
	maximumAge: 5000, // 5 seconds cached geolocation is good enough
	// https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/timeout
	timeout: 15000 // 15 seconds
};

// https://leafletjs.com/reference-1.3.4.html#map-zoom
export const MAP_MIN_ZOOM = 4;
export const MAP_DEFAULT_ZOOM = 17;
export const MAP_MAX_ZOOM = 18;

export const MAP_PREVIEW_MIN_ZOOM = 14;
export const MAP_PREVIEW_DEFAULT_ZOOM = 17;
export const MAP_PREVIEW_MAX_ZOOM = 18;

export const MAP_ATTRIBUTION = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

// the max meters required for the user to be within the vicinity of an item (to track as a POI visit)
export const VISIT_METERS = 50;

// the time after which a revisit to a POI will be tracked
export const VISIT_TTL = 1000 * 60 * 60 * 24;

// the amount of recently clicked items in history
export const MAX_CLICKED_HISTORY = 20;

// the max meters required for the user to be within the vicinity of an item (to play a puzzle etc)
export const VICINITY_METERS = 1000;

// https://github.com/Leaflet/Leaflet.markercluster#other-options
export const MAP_DISABLE_CLUSTERING_AT_ZOOM = 16;

// https://github.com/Leaflet/Leaflet.markercluster#other-options
export const MAP_MAX_CLUSTER_RADIUS = 70;

export const UPLOAD_MIME_TYPES = ["image/png", "image/gif", "image/jpeg"];

export const LOCALES = [
	{ code: "en", title: "English" },
	{ code: "it", title: "Italiano" },
	{ code: "es", title: "Español" },
	{ code: "fr", title: "Français" },
	{ code: "de", title: "Deutsch" },
	{ code: "pl", title: "Polski" }
];

export const WEB_PUSH_PUBLIC_KEY =
	"BAQbPk7f_Vnyh2ZIkKoqNIz1J_IpzqEu6tww7Les-Zf5KcUXCVZ8ogVE6nkVdA-_Oe1QBQeZHKGTtTsMQ4xqXBg";

export const OSM_TILES_SERVER = "https://europa.eu/webtools/maps/tiles/osm-ec/{z}/{x}/{y}.png";

export default () => {
	removeFbclid();
	configureAxios();
	registerServiceWorker();
	setTimeout(() => {
		// Delay execution of EU's cookie consent modal so:
		// - it can find out correct language from <html lang=".." />
		// - we don't load junk in the critical path.
		showCookieConsentModal(() => {
			// As soon as the cookie consent modal is done loading, load piwik.
			if (!window.euCookieConsent) return;
			if (!window.euCookieConsent.accepted("europa")) return;
			startPiwikTracking();
		});
	}, 1000);
};

export const BACKEND_URL = "/cms/";
export const BACKEND_ECAS_URL = `${BACKEND_URL}ecas`;

function configureAxios() {
	console.log("Configuring axios");
	axios.defaults.timeout = process.env.NODE_ENV === "production" ? 20000 : 10000;
	axios.defaults.baseURL = BACKEND_URL;
	axiosCancel(axios, { debug: false });
}

function registerServiceWorker() {
	if (!("serviceWorker" in navigator)) {
		console.warn("Service workers are not supported by this browser");
		return;
	}
	console.log("Registering service worker");
	navigator.serviceWorker
		.register("/service-worker.js", {
			scope: "/"
		})
		.then(
			() => {
				console.log("Service worker has been registered");
			},
			e => {
				console.error("Service worker registration failed", e);
			}
		);
}
function showCookieConsentModal(callback) {
	// If DNT is enabled then do not show cookie consent modal.
	// Callback will not be run as well.
	if (window.navigator.doNotTrack) {
		console.log("DNT option found.");
		return;
	}

	// Documentation on consent modal:
	// C3M-203
	// - https://webgate.ec.europa.eu/fpfis/wikis/pages/viewpage.action?pageId=103001757
	// - https://webgate.ec.europa.eu/fpfis/wikis/display/webtools/Cookie+Consent+Kit+-+Technical+details

	// Configure modal. Not sure what the value of "europa" does exactly.
	// Accepting it results in:
	// %7B%22a%22%3A%7B%22europa%22%3A%5B%22p%22%5D%7D%2C%22r%22%3A%7B%7D%7D which is {"a":{"europa":["p"]},"r":{}}
	// %7B%22a%22%3A%7B%7D%2C%22r%22%3A%7B%22europa%22%3A%5B%22p%22%5D%7D%7D which is {"a":{},"r":{"europa":["p"]}}
	window.consent_required_cookies = { a: { europa: ["p"] } };

	// Run modal.
	const script = document.createElement("script");
	script.type = "text/javascript";
	script.src = "https://ec.europa.eu/wel/cookie-consent/consent.js";
	script.defer = "defer";
	script.async = "async";
	window.document.head.appendChild(script);

	// When it loads, call the passed callback.
	if (callback) script.onload = callback;
}

function startPiwikTracking() {
	// Only do piwik tracking on a production build.
	if (process.env.NODE_ENV !== "production") return;

	// Only do piwik tracking on the production hostname.
	if (window.location.hostname !== "culturalgems.jrc.ec.europa.eu") return;

	const piwik = new ReactPiwik({
		url: "stats.jrc.ec.europa.eu",
		siteId: 207,
		trackErrors: true,
		enableLinkTracking: true,
		trackDocumentTitle: true
	});
	// track the initial pageview
	ReactPiwik.push(["trackPageView"]);
	// connect piwik to history
	piwik.connectToHistory(history);
}
