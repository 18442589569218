import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class Loading extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired
	};

	render() {
		const { t } = this.props;
		return (
			<div className="lds-ripple">
				<span />
				<span />
				<div>
					{t("loading")}<br />
					{t("please.wait")}
				</div>
			</div>
		);
	}
}
